.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color-primary {
  color: var(--bs-primary);
}
.error {
  color: red;
}

.success {
  color: green;
}

.table-clickable tbody tr {
  cursor: pointer;
}

.form-label {
  font-weight: bold;
}
.label {
  font-weight: bold;
}

.right {
  text-align: end;
}

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th,
table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td,
table.table-fit tfoot td {
  width: auto !important;
}

.btn-link-noborder {
  border: 0 !important;
  padding: 0px;
}

.fc-daygrid-event {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.table thead {
  border-bottom: 2px solid black;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-body-fullscreen {
  max-height: unset;
  overflow-y: auto;
}

.modal-body-no-footer {
  max-height: calc(100vh - 130px);
  overflow-y: auto;
}

.dropdown.active>a {
  color: black !important;
}